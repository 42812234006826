(function($) {

  skel.breakpoints({
    wide: '(max-width: 1680px)',
    normal: '(max-width: 1280px)',
    narrow: '(max-width: 980px)',
    mobile: '(max-width: 736px)',
    mobilep: '(max-width: 480px)'
  });

  $(function() {

    var $window = $(window),
      $body = $('body'),
      $header = $('#header'),
      $banner = $('#banner');

    // Fix: Placeholder polyfill.
      $('form').placeholder();

    // Prioritize "important" elements on narrow.
      // skel.on('+narrow -narrow', function() {
      //   $.prioritize(
      //     '.important\\28 narrow\\29',
      //     skel.breakpoint('narrow').active
      //   );
      // });

    // Dropdowns.
      $('#nav > ul').dropotron({
        alignment: 'center'
      });

    // Off-Canvas Navigation.

      // Navigation Button.
        $(
          '<div id="navButton">' +
            '<a href="#navPanel" class="toggle"></a>' +
          '</div>'
        )
          .appendTo($body);

      // Navigation Panel.
        $(
          '<div id="navPanel">' +
            '<nav>' +
              $('#nav').navList() +
            '</nav>' +
          '</div>'
        )
          .appendTo($body)
          .panel({
            delay: 500,
            hideOnClick: true,
            hideOnSwipe: true,
            resetScroll: true,
            resetForms: true,
            side: 'left',
            target: $body,
            visibleClass: 'navPanel-visible'
          });

      // Fix: Remove transitions on WP<10 (poor/buggy performance).
        if (skel.vars.os == 'wp' && skel.vars.osVersion < 10)
          $('#navButton, #navPanel, #page-wrapper')
            .css('transition', 'none');

    // Header.
    // If the header is using "alt" styling and #banner is present, use scrollwatch
    // to revert it back to normal styling once the user scrolls past the banner.
    // Note: This is disabled on mobile devices.
      if (!skel.vars.mobile
      &&  $header.hasClass('alt')
      &&  $banner.length > 0) {
          $banner.scrollwatch({
            delay:    0,
            range:    1,
            anchor:   'top',
            on:     function() { $header.addClass('alt reveal'); },
            off:    function() { $header.removeClass('alt'); }
          });
      }

  });

})(jQuery);
