// general JS (always add)
import '@mod-mijnsiteonline/nodejs/mso';

// main CSS
import './web/css/rtd.css'; // cannot be SCSS
import './exloo.scss';
import 'font-awesome/css/font-awesome.min.css';

// pages
import './pages/agenda/agenda';
import './pages/intranet/intranet';
import './pages/news/news';
import './pages/organizations/organizations';
import './pages/team/team';

// components
import './widgets/widgets';

// template specific JS files
window.$ = require('jquery');
window.jQuery = $;
require('./web/js/jquery.dropotron.min.js');
window.skel = require('./web/js/skel.min.js');
require('./web/js/jquery.scrollgress.min.js');
require('./web/js/util.js');
require('./web/js/main.js');

import * as DomPack from 'dompack';

DomPack.onDomReady(() => {
  if ($('#sidebar').length) {
    let $sections = $('#sidebar > section');
    $sections.each(function(idx, el) {
      if (idx < $sections.length - 1) {
        $(this).after('<hr />');
      }
    });
  }
});
